import React from "react";

export default (props) => (
  <footer className="main-footer">
    <strong>
      Copyright © <a href="https://r.jjales.com/" target="_blank">rJales </a>
    </strong>
    All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 1.0
    </div>
  </footer>
);
