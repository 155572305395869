import React from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

export default (props) => {
  return (
    <div className="cardAtivo">
      <div className="card-body">
        <span>Ativo: </span>
        <BootstrapSwitchButton
          checked={props.checked}
          onChange={() => props.whenChanged(!props.checked)}
          size="xs"
        />
      </div>
    </div>
  );
};
