import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import If from "../common/operator/if";
import { returnDateDiaMesAno } from "../common/functions/returnDate.js";
import Avatar from "../common/images/avatar.jpeg";
import getAge from "../common/functions/getAge";
import Card from "../common/tabInline/card";
import CardHeader from "../common/tabInline/cardHeader";
import CardBody from "../common/tabInline/cardBody";
import TabsHeader from "../common/tabInline/tabsHeader";
import TabHeader from "../common/tabInline/tabHeader";
import TabsContent from "../common/tabInline/tabsContent";
import TabContent from "../common/tabInline/tabContent";
import Anamnese from "./anamnese";
import Avaliacao from "./avaliacao";
import Recordatorio from "./recordatorio";

import * as pacientesActionCreators from "../pacientes/pacientesActions";

import { config } from "../common/base";

const CadastroForm = (props) => {
  const dadosPessoais = useSelector(
    (state) => state.paciente.values[0],
    shallowEqual
  );

  const anamneseListagem = useSelector(
    (state) => state.paciente.anamneseListagem,
    shallowEqual
  );

  const anamnese = useSelector(
    (state) => state.paciente.anamnese,
    shallowEqual
  );

  const recordatorio = useSelector(
    (state) => state.paciente.recordatorio,
    shallowEqual
  );

  const avaliacaoListagem = useSelector(
    (state) => state.paciente.avaliacaoListagem,
    shallowEqual
  );

  const avaliacao = useSelector(
    (state) => state.paciente.avaliacao,
    shallowEqual
  );

  const dispatch = useDispatch();
  const {
    getAnamnese,
    getRecordatorio,
    getAnamneseListagem,
    initConsultas,
    getAvaliacao,
    getAvaliacoesListagem,
  } = bindActionCreators(pacientesActionCreators, dispatch);

  const [selected, setSelected] = useState("tabAnamnese");

  const [novaConsulta, setNovaConsulta] = useState(false);

  // FUNC PARA CLIQUE NA LISTA DOS ANAMENSES NO CANTO INFERIOR ESQUERDO
  const clickConsulta = (e) => {
    initConsultas(e);
    setSelected("tabAnamnese");
  };

  // LISTAGEM DAS ANAMENSES
  const funcAnamneseListagem = Object.entries(anamneseListagem).map(
    (anamneseList, index) => {
      let hr =
        index < Object.entries(anamneseListagem).length - 1 ? <hr /> : "";
      let ativo = anamneseList[1].dataDesativacao == null ? "Ativo" : "Inativo";
      let tipo =
        anamneseList[1].dataDesativacao == null
          ? "ribbon bg-info"
          : "ribbon bg-danger";

      return (
        <React.Fragment key={index}>
          <div
            onClick={() => clickConsulta(anamneseList[1]._id)}
            className="position-relative p-3 bg-gray card-consultas-historico"
          >
            <div className="ribbon-wrapper">
              <div className={tipo}>{ativo}</div>
            </div>
            Desde: {returnDateDiaMesAno(anamneseList[1].createdAt)} <br />
            <small>
              Última Aval / Acomp:{" "}
              {anamneseList[1].ultimaAvaliacao == ""
                ? "####"
                : returnDateDiaMesAno(anamneseList[1].ultimaAvaliacao)}
            </small>
          </div>
          {hr}
        </React.Fragment>
      );
    }
  );

  // FUNC PARA INICIAR NOVA CONSULTA
  const initNovaConsulta = () => {
    setNovaConsulta(true);
    initConsultas();
  };

  // SET DE NEW ABA
  const changeSelected = (e) => {
    setSelected(e);
  };

  // SUBMIT ANAMNESE
  const submitAnamnese = (values) => {
    const id = values._id ? values._id : "";
    const method = values._id ? "put" : "post";
    const msg = values._id ? "alterar a" : "inserir uma";
    axios[method](`${config.BASE_URL}/anamnese/${id}`, values)
      .then((resp) => {
        toastr.success(
          "Sucesso",
          `Você acabou de ${msg} Anamnese Nutricional!!!`
        );
        getAnamnese(resp.data._id);
        getRecordatorio(resp.data._id);
        getAnamneseListagem(resp.data.paciente);
        setHtmlAnamneseListagem(funcAnamneseListagem);
      })
      .catch((e) => {
        e.response.data.errors.forEach((error) => toastr.error("Erro", error));
      });
  };

  // SUBMIT AVALIACAO
  const submitAvaliacao = (values) => {
    const id = values._id ? values._id : "";
    const method = values._id ? "put" : "post";
    const msg = values._id ? "alterar" : "inserir";
    axios[method](`${config.BASE_URL}/avaliacao/${id}`, values)
      .then((resp) => {
        toastr.success(
          "Sucesso",
          `Você acabou de ${msg} uma Avaliação Nutricional!!!`
        );
        getAvaliacoesListagem(resp.data.anamnese);
        getAvaliacao(resp.data._id);
      })
      .catch((e) => {
        e.response.data.errors.forEach((error) => toastr.error("Erro", error));
      });
  };

  // SUBMIT RECORDATORIO
  const submitRecordatorio = (values) => {
    const id = values._id ? values._id : "";
    const method = values._id ? "put" : "post";
    const msg = values._id ? "alterar o" : "inserir um";
    axios[method](`${config.BASE_URL}/recordatorio/${id}`, values)
      .then((resp) => {
        toastr.success(
          "Sucesso",
          `Você acabou de ${msg} Recordatório Alimentar Habitual!!!`
        );
        getRecordatorio(resp.data.anamnese);
      })
      .catch((e) => {
        e.response.data.errors.forEach((error) => toastr.error("Erro", error));
      });
  };

  const [htmlAnamneseListagem, setHtmlAnamneseListagem] =
    useState(funcAnamneseListagem);

  useEffect(() => {
    setHtmlAnamneseListagem(funcAnamneseListagem);
  }, [anamneseListagem, props.anamneseAtivo]);

  return (
    <React.Fragment>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div
              className={
                Object.keys(anamnese).length || novaConsulta == true
                  ? "col-md-3"
                  : "col-md-12"
              }
            >
              <div className="card card-primary card-outline">
                <div className="card-body box-profile">
                  <div className="text-center">
                    <img
                      className="profile-user-img img-fluid img-circle"
                      src={Avatar}
                      alt="User profile picture"
                    />
                  </div>
                  <h3 className="profile-username text-center">
                    {dadosPessoais.nome}
                  </h3>
                  <p className="text-muted text-center">
                    {dadosPessoais.profissao}
                  </p>
                  <ul className="list-group list-group-unbordered mb-3">
                    <li className="list-group-item">
                      <b>Idade</b>
                      <a className="float-right">
                        {getAge(dadosPessoais.nascimento) || 0}
                      </a>
                    </li>
                    <li className="list-group-item">
                      <b>Sexo</b>
                      <a className="float-right firstUp">
                        {dadosPessoais.sexo}
                      </a>
                    </li>
                    <li className="list-group-item">
                      <b>Email</b>
                      <a className="float-right">{dadosPessoais.email}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Consultas</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <If show={Object.keys(anamneseListagem).length}>
                    {htmlAnamneseListagem}
                  </If>
                  <If show={Object.keys(anamneseListagem).length == 0}>
                    <div
                      className="position-relative p-3 bg-gray card-consultas-historico"
                      onClick={initNovaConsulta}
                    >
                      <div className="ribbon-wrapper">
                        <div className="ribbon bg-info">Novo</div>
                      </div>
                      Iniciar Nova Consulta
                    </div>
                  </If>
                </div>
              </div>
            </div>
            <If show={Object.keys(anamnese).length || novaConsulta == true}>
              <div className="col-md-9">
                <Card>
                  <CardHeader>
                    <TabsHeader
                      onchangeSelected={changeSelected}
                      selected={selected}
                      nav="nav-pills"
                    >
                      <TabHeader
                        icon="far fa-list-alt"
                        label="Anamnese Nutricional"
                        target="tabAnamnese"
                      />
                      <TabHeader
                        icon="far fa-list-alt"
                        label="Recordatório Alimentar Habitual"
                        target="tabRecordatorio"
                      />
                      <TabHeader
                        icon="far fa-list-alt"
                        label="Avaliação / Acompanhamento"
                        target="tabAvaliacao"
                      />
                    </TabsHeader>
                  </CardHeader>
                  <CardBody>
                    <TabsContent>
                      <TabContent selected={selected} id="tabAnamnese">
                        <Anamnese
                          onSubmit={submitAnamnese}
                          paciente={dadosPessoais._id}
                          anamnese={anamnese}
                        />
                      </TabContent>
                      <TabContent selected={selected} id="tabRecordatorio">
                        <Recordatorio
                          onSubmit={submitRecordatorio}
                          recordatorio={recordatorio}
                          anamnese={anamnese}
                        />
                      </TabContent>
                      <TabContent selected={selected} id="tabAvaliacao">
                        <Avaliacao
                          onSubmit={submitAvaliacao}
                          changeAvaliacao={getAvaliacao}
                          avaliacaoListagem={avaliacaoListagem}
                          avaliacao={avaliacao}
                          anamnese={anamnese}
                          dadosPessoais={dadosPessoais}
                        />
                      </TabContent>
                    </TabsContent>
                  </CardBody>
                </Card>
              </div>
            </If>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CadastroForm;
