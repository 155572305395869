import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import PacienteReducer from "../pacientes/pacientesReducer";
import AuthReducer from "../auth/authReducer";

const rootReducer = combineReducers({
  paciente: PacienteReducer,
  toastr: toastrReducer,
  auth: AuthReducer,
});

export default rootReducer;
