import React from "react";

export default (props) => {
  return (
      <li className="nav-item">
        <a
          className={`nav-link ${props.selected == props.target ? "active" : ""}`}
          href="#"
          data-toggle="tab"
          onClick={(e) => {e.preventDefault();props.onchangeSelected(props.target)}}
          data-target={props.target}
          title={props.label}
        >{props.label}</a>
      </li>
  );
};
