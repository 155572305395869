import React, { useState, useRef, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import ContentHeader from "../common/template/contentHeader";
import If from "../common/operator/if";
import { config } from "../common/base";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as pacientesActionCreators from "./pacientesActions";

import Listagem from "./listagem";
import Cadastro from "./cadastro";
import Consulta from "../consultas/consulta";

const scroll = () => {
  setTimeout(() => {
    const section = document.querySelector(".acoes");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  }, 100);
};

export default (props) => {
  const [whatToShow, setWhatToShow] = useState("");
  const [showHash, setShowHash] = useState("");
  const [anamneseAtivo, setAnamneseAtivo] = useState(true);

  const dispatch = useDispatch();

  const { create, update, remove, showUpdate, getAnamneseListagem } =
    bindActionCreators(pacientesActionCreators, dispatch);

  // Chamada do Evento de Criar um novo Paciente
  const callCreate = (values) => {
    create(values);
    setWhatToShow("edit");
    scroll();
  };

  // Chamada do Evento de Edicao de um Paciente
  const callEdit = (e) => {
    showUpdate(e);
    setWhatToShow("edit");
  };

  // Cahamda do Evento de Remocao de um Paciente
  const callRemove = (e) => {
    remove({ _id: e });
    setWhatToShow("list");
    setShowHash((Math.random() + 1).toString(36).substring(7));
    scroll();
  };

  // Desativa a conta e coloca dataDesativacao no Anamnese
  const desativar = (pacienteId) => {
    update({ _id: pacienteId, ativo: false });
    axios.get(
      `${config.BASE_URL}/anamnese/desativarAnamnese?paciente=${pacienteId}`
    );
    getAnamneseListagem(pacienteId);
    setAnamneseAtivo(false);
  };

  // Ativa a conta
  const ativar = (pacienteId) => {
    update({ _id: pacienteId, ativo: true });
  };

  const showDelete = (pacienteId, tipo) => {
    // Se tiver alguma historico de consulta, ele inativa
    toastr.confirm("Tem certeza que deseja continuar ?!", {
      onOk: () =>
        tipo == "Desativar" ? desativar(pacienteId) : callRemove(pacienteId),
    });
  };

  return (
    <React.Fragment>
      <ContentHeader titulo="Pacientes" compl={whatToShow} />
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Ações</h3>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
            >
              <i className="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div className="card-body">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div
                  className={`${
                    whatToShow == "edit" || whatToShow == "consulta"
                      ? "col-lg-3"
                      : "col-lg-6"
                  } col-12`}
                >
                  <div className="small-box bg-success">
                    <If show={whatToShow == "list"}>
                      <div className="overlay"></div>
                    </If>
                    <div className="inner">
                      <h3>List</h3>
                      <p>Edição / Consultas</p>
                    </div>
                    <div className="icon">
                      <i className="far fa-list-alt"></i>
                    </div>
                    <a
                      href="#"
                      className="small-box-footer"
                      onClick={(e) => {
                        e.preventDefault();
                        setWhatToShow("list");
                        setShowHash(
                          (Math.random() + 1).toString(36).substring(7)
                        );
                        scroll();
                      }}
                    >
                      Clique aqui <i className="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
                <div
                  className={`${
                    whatToShow == "edit" || whatToShow == "consulta"
                      ? "col-lg-3"
                      : "col-lg-6"
                  } col-12`}
                >
                  <div className="small-box bg-info">
                    <If show={whatToShow == "add"}>
                      <div className="overlay"></div>
                    </If>
                    <div className="inner">
                      <h3>Add</h3>

                      <p>Adicionar novo Paciente</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-plus-circle"></i>
                    </div>
                    <a
                      href="#"
                      className="small-box-footer"
                      onClick={(e) => {
                        e.preventDefault();
                        setWhatToShow("add");
                        scroll();
                      }}
                    >
                      Clique aqui <i className="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
                <If show={whatToShow == "edit" || whatToShow == "consulta"}>
                  <div className="col-lg-3 col-12">
                    <div className="small-box bg-warning">
                      <If show={whatToShow == "edit"}>
                        <div className="overlay"></div>
                      </If>
                      <div className="inner">
                        <h3>Edit</h3>
                        <p>Editar Paciente</p>
                      </div>
                      <div className="icon">
                        <i className="fas fa-user-edit"></i>
                      </div>
                      <a
                        href="#"
                        className="small-box-footer"
                        onClick={(e) => {
                          e.preventDefault();
                          setWhatToShow("edit");
                          scroll();
                        }}
                      >
                        Clique aqui{" "}
                        <i className="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-12">
                    <div className="small-box bg-danger">
                      <If show={whatToShow == "consulta"}>
                        <div className="overlay"></div>
                      </If>
                      <div className="inner">
                        <h3>Consulta</h3>

                        <p>Atividades do Paciente</p>
                      </div>
                      <div className="icon">
                        <i className="fas fa-stethoscope"></i>
                      </div>
                      <a
                        href="#"
                        className="small-box-footer"
                        onClick={(e) => {
                          e.preventDefault();
                          setWhatToShow("consulta");
                          scroll();
                        }}
                      >
                        Clique aqui{" "}
                        <i className="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                </If>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="acoes">
        <If show={whatToShow == "list"}>
          <Listagem onEdit={callEdit} hash={showHash} />
        </If>
        <If show={whatToShow == "add"}>
          <Cadastro
            values={{ _state: "" }}
            onSubmit={callCreate}
            tipo="inserir"
            icon="fas fa-save"
          />
        </If>
        <If show={whatToShow == "edit"}>
          <Cadastro
            tipo="edit"
            onSubmit={update}
            onDelete={showDelete}
            ativar={ativar}
            icon="fas fa-save"
          />
        </If>
        <If show={whatToShow == "consulta"}>
          <Consulta
            anamneseAtivo={anamneseAtivo}
            button="Consulta"
            icon="fas fa-edit"
          />
        </If>
      </div>
    </React.Fragment>
  );
};
