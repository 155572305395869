import React from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActionCreators from "../../auth/authActions";

export default (props) => {
  const dispatch = useDispatch();
  const { logout } = bindActionCreators(authActionCreators, dispatch);

  return(
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    {/* <!-- Left navbar links --> */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#" role="button">
          <i className="fas fa-bars"></i>
        </a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="/" className="nav-link">
          Home
        </a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a
          href="https://api.whatsapp.com/send?phone=5511964594499"
          target="_blank"
          className="nav-link"
        >
          Contact
        </a>
      </li>
    </ul>
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
          <i className="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          data-widget="control-sidebar"
          data-slide="true"
          href="#"
          role="button"
          onClick={(e) => {e.preventDefault();logout()}}
        >
          <i className="fas fa-sign-out-alt"></i>
        </a>
      </li>
    </ul>
  </nav>
  )
};
