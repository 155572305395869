import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import ContentHeader from "../common/template/contentHeader";
import { returnDateDiaMesAno } from "../common/functions/returnDate";
import axios from "axios";
import { config } from "../common/base";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as pacientesActionCreators from "../pacientes/pacientesActions";

export default (props) => {
  const dadosPessoais = useSelector((state) => state.paciente.values[0]);

  const dispatch = useDispatch();
  const { update } = bindActionCreators(pacientesActionCreators, dispatch);

  const [qtdePacienteAtivos, setQtdePacienteAtivos] = useState(0);
  const [qtdePacienteInativos, setQtdePacienteInativos] = useState(0);
  const [qtdeAvaliacao, setQtdeAvaliacao] = useState(0);
  const [lastAvaliacao, setLastAvaliacao] = useState("###");
  const [lastPaciente, setLastPaciente] = useState("###");
  const [lista, setLista] = useState("");

  const desativar = (index, pacienteId) => {
    // Se tiver alguma historico de consulta, ele inativa
    toastr.confirm("Tem certeza que deseja continuar ?!", {
      onOk: () => {
        update({ _id: pacienteId, ativo: false });
        axios.get(
          `${config.BASE_URL}/anamnese/desativarAnamnese?paciente=${pacienteId}`
        );
      },
    });
  };

  useEffect(() => {
    axios.get(`${config.BASE_URL}/pacientes/count?ativo=true`).then((resp) => {
      setQtdePacienteAtivos(resp.data.value);
    });
    axios.get(`${config.BASE_URL}/pacientes/count?ativo=false`).then((resp) => {
      setQtdePacienteInativos(resp.data.value);
    });
    axios.get(`${config.BASE_URL}/avaliacao/count`).then((resp) => {
      setQtdeAvaliacao(resp.data.value);
    });
    axios.get(`${config.BASE_URL}/avaliacao/lastAvaliacao`).then((resp) => {
      setLastAvaliacao(resp.data.data);
      setLastPaciente(resp.data.paciente);
    });
    axios.get(`${config.BASE_URL}/avaliacao/semAtividade`).then((resp) => {
      const xLista = resp.data.map((list, index) => {
        return (
          <li className={`paciente_${index}`} key={index}>
            <span className="text">{list.nome}</span>
            <small className="badge badge-danger">
              <i className="far fa-clock"></i> {returnDateDiaMesAno(list.data)}
            </small>
            <div className="tools">
              <i
                onClick={() => desativar(index, list.paciente)}
                className="fas fa-edit"
              ></i>
            </div>
          </li>
        );
      });
      setLista(xLista.length ? xLista : <span>::Não há registros::</span>);
    });
  }, [dadosPessoais]);

  return (
    <div>
      <ContentHeader titulo="Dashboard" />
      <div className="row">
        <div className="col-sm-6 col-12">
          <div className="info-box bg-gradient-info">
            <span className="info-box-icon">
              <i className="fas fa-user-check"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Pacientes Ativos</span>
              <span className="info-box-number">{qtdePacienteAtivos}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-12">
          <div className="info-box bg-gradient-danger">
            <span className="info-box-icon">
              <i className="fas fa-user-lock"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Pacientes Inativos</span>
              <span className="info-box-number">{qtdePacienteInativos}</span>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-12">
          <div className="info-box bg-gradient-success">
            <span className="info-box-icon">
              <i className="far fa-thumbs-up"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Avaliações</span>
              <span className="info-box-number">{qtdeAvaliacao}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-12">
          <div className="info-box bg-gradient-warning">
            <span className="info-box-icon">
              <i className="far fa-calendar-alt"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Última Avaliação</span>
              <span className="info-box-number">
                {lastPaciente} - {returnDateDiaMesAno(lastAvaliacao)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-default color-palette-box">
        <div className="card-header">
          <h3 className="card-title">
            <i className="fas fa-exclamation-triangle"></i> Pacientes com 6 meses ou mais em inatividade
          </h3>
        </div>
        <div className="card-body">
          <ul className="todo-list ui-sortable" data-widget="todo-list">
            {lista}
          </ul>
        </div>
      </div>
    </div>
  );
};
