import React from "react";
import Logo from "../images/logo.png";
import Juli from "../images/juliana.jpeg";
import { useSelector } from "react-redux";

export default (props) => {
  const user = useSelector((state) => state.auth.user);
  
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* <!-- Brand Logo --> */}
      <a href="/" className="brand-link">
        <img
          src={Logo}
          alt="NutriAdmin"
          className="brand-image img-circle elevation-3"
        />
        <span className="brand-text font-weight-light">NutriAdmin</span>
      </a>
      {/* <!-- Sidebar --> */}
      <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
        <div className="os-resize-observer-host observed">
          <div className="os-resize-observer"></div>
        </div>
        <div className="os-size-auto-observer observed">
          <div className="os-resize-observer"></div>
        </div>
        <div className="os-content-glue"></div>
        <div className="os-padding">
          <div className="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-overlaid">
            <div className="os-content">
              {/* <!-- Sidebar user panel (optional) --> */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img
                    src={Juli}
                    className="img-circle elevation-2"
                    alt="User Image"
                  />
                </div>
                <div className="info">
                  <a className="d-block">{user.name}</a>
                </div>
              </div>

              {/* <!-- SidebarSearch Form --> */}
              <div className="form-inline">
                {/* <div className="input-group" data-widget="sidebar-search">
                  <input
                    className="form-control form-control-sidebar"
                    type="search"
                    placeholder="Procurar"
                    aria-label="Procurar"
                    disabled
                  />
                  <div className="input-group-append">
                    <button className="btn btn-sidebar">
                      <i className="fas fa-search fa-fw"></i>
                    </button>
                  </div>
                </div> */}
                {/* <div className="sidebar-search-results">
                  <div className="list-group">
                    <a href="#" className="list-group-item">
                      <div className="search-title">
                        <strong className="text-light"></strong>N
                        <strong className="text-light"></strong>o
                        <strong className="text-light"></strong>{" "}
                        <strong className="text-light"></strong>e
                        <strong className="text-light"></strong>l
                        <strong className="text-light"></strong>e
                        <strong className="text-light"></strong>m
                        <strong className="text-light"></strong>e
                        <strong className="text-light"></strong>n
                        <strong className="text-light"></strong>t
                        <strong className="text-light"></strong>{" "}
                        <strong className="text-light"></strong>f
                        <strong className="text-light"></strong>o
                        <strong className="text-light"></strong>u
                        <strong className="text-light"></strong>n
                        <strong className="text-light"></strong>d
                        <strong className="text-light"></strong>!
                        <strong className="text-light"></strong>
                      </div>
                      <div className="search-path"></div>
                    </a>
                  </div>
                </div> */}
              </div>

              {/* <!-- Sidebar Menu --> */}
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-header">CMS</li>
                  <li className="nav-item">
                    <a href="/" className="nav-link">
                      <i className="fas fa-home"></i> <p>Dashboard</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/pacientes" className="nav-link">
                      <i className="fas fa-users"></i> <p>Pacientes</p>
                    </a>
                  </li>
                  <li className="nav-header">SITE</li>
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>
                        Dashboard
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <a href="./index.html" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Dashboard v1</p>
                        </a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </nav>
              {/* <!-- /.sidebar-menu --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.sidebar --> */}
    </aside>
  );
};
