import React from "react";
import { Form, Field } from "react-final-form";
import If from "../common/operator/if";

const AnamneseForm = (props) => {
  let formData = { ...props.anamnese, paciente: props.paciente };
  return (
    <div className="post">
      <Form onSubmit={props.onSubmit} initialValues={{ ...formData }}>
        {({ handleSubmit, form, dirtyFields }) => (
          <form
            role="form"
            onSubmit={(event) => {
              handleSubmit(event);
              // if (dirtyFields["_state"] && props.button == "Incluir")
              //   form.reset();
            }}
          >
            <Field name="_id" component="input" type="hidden" />
            <Field name="paciente" component="input" type="hidden" />
            <div className="user-block">
              <span>Objetivo Nutricional</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="objetivo"
                    component="textarea"
                    className="form-control"
                    rows="3"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Acompanhamento Médico?</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="acompanhamento"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Problema de Saúde?</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="saude"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Medicamentos Utilizados</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="medicamentos"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Exames Laboratoriais</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="exames"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Sintomas Físicos?</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="sintomas"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Alergia Alimentar ou Intolerencia?</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="alergia"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Não Come Algum Alimento?</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="alimento"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Bebida Alcoolica?</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="bebida"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Funcionamento Intestinal</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="intestinal"
                    component="textarea"
                    className="form-control"
                    rows="3"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Ingestao Hídrica</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="hidrica"
                    component="textarea"
                    className="form-control"
                    rows="3"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Horário de Acordar</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="acordar"
                    component="textarea"
                    className="form-control"
                    rows="3"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="user-block">
              <span>Horário de Dormir</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="dormir"
                    component="textarea"
                    className="form-control"
                    rows="3"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-3">
                <div className="form-group radioSide">
                  <label htmlFor="ladoDominante">Lado Dominante</label>
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      type="radio"
                      name="ladoDominante"
                      component="input"
                      value="destro"
                      checked
                    />
                    <label className="form-check-label">Destro</label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      type="radio"
                      name="ladoDominante"
                      component="input"
                      value="canhoto"
                    />
                    <label className="form-check-label">Canhoto</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-9">
                <label htmlFor="Tipo de Atividade">Tipo de Atividade</label>
                <Field
                  name="tipoAtividade"
                  component="select"
                  className="form-control"
                >
                  <option value="">Selecione</option>
                  <option value="leve">Leve</option>
                  <option value="moderada">Moderada</option>
                  <option value="intensa">Intensa</option>
                </Field>
              </div>
            </div>
            <div className="anamneseAtividade user-block">
              <span>Atividade Física?</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="atividade"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <If show={formData["dataDesativacao"] == null}>
              <div className="row">
                <div className="offset-md-10 col-md-2 offset-6 col-6">
                  <button
                    type="submit"
                    className="btn btn-block btn-outline-primary btn-sm"
                    title="Salvar"
                  >
                    <i className="fas fa-save"></i> Salvar
                  </button>
                </div>
              </div>
            </If>
          </form>
        )}
      </Form>
    </div>
  );
};

export default AnamneseForm;
