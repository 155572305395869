// FORMATA A DATA PARA 4 DIGITOS
export function returnDate(data, fields){
    fields.forEach(function(e){
        data[0][e] = data[0][e].substr(0, 10);
      });
    return data;
}

// FORMATA A DATA DIA/MES/ANO
export function returnDateDiaMesAno(value){
  value = value == undefined || value == null ? '' : value;
  return value.substr(0, 10).split('-').reverse().join('/');
}

