import React from "react";

export default (props) => {
  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-12">
            <h4>{props.titulo} <small> {props.compl ? `- ${props.compl}` : ''}</small></h4>
            <hr />
          </div>
        </div>
      </div>
    </section>
  );
};
