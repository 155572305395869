import React from "react";

import Header from "../common/template/header";
import Menu from "../common/template/menu";
import Footer from "../common/template/footer";
import Routes from "./routes";
import Messages from "../common/msg/messages";

export default (props) => {
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="container-main">
          <Routes />
        </div>
      </div>
      <Footer />
      <Messages />
    </div>
  );
};
