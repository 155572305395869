import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import formatString from "format-string-by-pattern";
import If from "../common/operator/if";
import getAge from "../common/functions/getAge";

import { useSelector, shallowEqual } from "react-redux";

const CadastroForm = (props) => {
  const values = useSelector((state) => state.paciente.values, shallowEqual);
  const anamneseListagem = useSelector((state) => state.paciente.anamneseListagem, shallowEqual);

  let formData = props.tipo == 'edit' ? {...values[0] } : { ...props.values };
  const [idade, setIdade] = useState(getAge(formData["nascimento"]));

  const buttonApagar = Object.entries(anamneseListagem).length ? 'Desativar' : 'Apagar';
  
  return (
    <React.Fragment>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-info">
            <div className="card-body">
              <Form onSubmit={props.onSubmit} initialValues={{ ...formData }}>
                {({ handleSubmit, form, dirtyFields }) => (
                  <form
                    role="form"
                    onSubmit={(event) => {
                      handleSubmit(event);
                      // if (dirtyFields["_state"] && props.tipo == 'inserir')
                      //   form.reset();
                    }}
                  >
                    <Field name="_id" component="input" type="hidden" />
                    <Field name="_state" component="input" type="hidden" />
                    <Field name="ativo" component="input" type="hidden" />
                    <If show={props.tipo == 'edit'}>
                      <div className="row">
                        <If show={formData["ativo"] == true}>
                          <div className="offset-md-10 col-md-2 offset-6 col-6">
                            <button
                              type="button"
                              className="btn btn-block btn-outline-danger btn-sm"
                              title={buttonApagar}
                              onClick={() => props.onDelete(formData["_id"], buttonApagar)}
                            >
                              <i className="fas fa-trash-alt"></i>{" "}
                              {buttonApagar}
                            </button>
                          </div>
                        </If>
                        <If show={formData["ativo"] == false}>
                          <div className="offset-md-10 col-md-2 offset-6 col-6">
                            <button
                              type="button"
                              className="btn btn-block btn-outline-primary btn-sm"
                              title="Ativar"
                              onClick={() => props.ativar(formData["_id"])}
                            >
                              <i className="fas fa-charging-station"></i> Ativar
                            </button>
                          </div>
                        </If>
                      </div>
                    </If>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="nome">Nome</label>
                          <Field
                            name="nome"
                            component="input"
                            type="text"
                            placeholder="Nome"
                            className="form-control nome"
                            // autoComplete="off"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <label htmlFor="Sexo">Sexo</label>
                        <Field
                          name="sexo"
                          component="select"
                          className="form-control"
                          required
                        >
                          <option value="">Selecione</option>
                          <option value="masculino">Masculino</option>
                          <option value="feminino">Feminino</option>
                        </Field>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label htmlFor="nascimento">Data de Nascimento</label>
                          <Field
                            name="nascimento"
                            component="input"
                            type="date"
                            className="form-control"
                            required
                          />
                          <OnChange name="nascimento">
                            {(value, previous) => {
                              setIdade(getAge(value));
                            }}
                          </OnChange>
                          <p className="idade text-info">{idade || 0} ano(s)</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label htmlFor="residencial">
                            Telefone Residencial
                          </label>
                          <Field
                            name="residencial"
                            component="input"
                            type="text"
                            placeholder="Telefone Residencial"
                            className="form-control"
                            autoComplete="off"
                            parse={formatString("+99 (99) 9999-99999")}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label htmlFor="celular">Telefone Celular</label>
                          <Field
                            name="celular"
                            component="input"
                            type="text"
                            placeholder="Telefone Celular"
                            className="form-control"
                            autoComplete="off"
                            required
                            parse={formatString("+99 (99) 99999-9999")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            name="email"
                            component="input"
                            type="email"
                            placeholder="Email"
                            autoComplete="off"
                            className="form-control email"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label htmlFor="estadoCivil">Estado Civil</label>
                          <Field
                            name="estadoCivil"
                            component="select"
                            className="form-control"
                            required
                          >
                            <option value="">Selecione</option>
                            <option value="solteiro">Solteiro</option>
                            <option value="casado">Casado</option>
                            <option value="separado">Separado</option>
                            <option value="divorciado">Divorciado</option>
                            <option value="viúvo">Viúvo</option>
                          </Field>
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <div className="form-group">
                          <label htmlFor="profissao">Profissão</label>
                          <Field
                            name="profissao"
                            component="input"
                            type="text"
                            placeholder="Profissão"
                            className="form-control"
                            autoComplete="off"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="offset-md-11 col-md-1 offset-6 col-6">
                        <button
                          type="submit"
                          className="btn btn-block btn-outline-primary btn-sm"
                          title="Salvar"
                        >
                          <i className={props.icon}></i> Salvar
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CadastroForm;
