import React from "react";
import { Form, Field } from "react-final-form";
import formatString from "format-string-by-pattern";
import If from "../common/operator/if";

const RecordatorioForm = (props) => {
  let formData = { ...props.recordatorio[0], anamnese: props.anamnese._id };
  return (
    <div className="post">
      <Form onSubmit={props.onSubmit} initialValues={{ ...formData }}>
        {({ handleSubmit, form, dirtyFields }) => (
          <form
            role="form"
            onSubmit={(event) => {
              handleSubmit(event);
              // if (dirtyFields["_state"] && props.button == "Incluir")
              //   form.reset();
            }}
          >
            <Field name="_id" component="input" type="hidden" />
            <Field name="anamnese" component="input" type="hidden" />
            <p className="recordatorioTitle">DesJejum</p>         
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="desjejum">Horário</label>
                  <Field
                    name="desjejum"
                    component="input"
                    type="text"
                    placeholder="Horário"
                    className="form-control"
                    autoComplete="off"
                    parse={formatString("99:99")}
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="alimentosDesjejum">Alimentos</label>
                  <Field
                    name="alimentosDesjejum"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="qtdeDesjejum">Quantidades</label>
                  <Field
                    name="qtdeDesjejum"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                <label htmlFor="obsDesjejum">Observações</label>
                  <Field
                    name="obsDesjejum"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <hr />
            <p className="recordatorioTitle">Intervalo da Manhã</p>         
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="manha">Horário</label>
                  <Field
                    name="manha"
                    component="input"
                    type="text"
                    placeholder="Horário"
                    className="form-control"
                    autoComplete="off"
                    parse={formatString("99:99")}                    
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="alimentosManha">Alimentos</label>
                  <Field
                    name="alimentosManha"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="qtdeManha">Quantidades</label>
                  <Field
                    name="qtdeManha"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                <label htmlFor="obsManha">Observações</label>
                  <Field
                    name="obsManha"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <hr />
            <p className="recordatorioTitle">Almoço</p>         
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="almoco">Horário</label>
                  <Field
                    name="almoco"
                    component="input"
                    type="text"
                    placeholder="Horário"
                    className="form-control"
                    autoComplete="off"
                    parse={formatString("99:99")}                    
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="alimentosAlmoco">Alimentos</label>
                  <Field
                    name="alimentosAlmoco"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="qtdeAlmoco">Quantidades</label>
                  <Field
                    name="qtdeAlmoco"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                <label htmlFor="obsAlmoco">Observações</label>
                  <Field
                    name="obsAlmoco"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <hr />
            <p className="recordatorioTitle">Intervalo da Tarde</p>         
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="tarde">Horário</label>
                  <Field
                    name="tarde"
                    component="input"
                    type="text"
                    placeholder="Horário"
                    className="form-control"
                    autoComplete="off"
                    parse={formatString("99:99")}                    
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="alimentosTarde">Alimentos</label>
                  <Field
                    name="alimentosTarde"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="qtdeTarde">Quantidades</label>
                  <Field
                    name="qtdeTarde"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                <label htmlFor="obsTarde">Observações</label>
                  <Field
                    name="obsTarde"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <hr />
            <p className="recordatorioTitle">Jantar</p>         
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="jantar">Horário</label>
                  <Field
                    name="jantar"
                    component="input"
                    type="text"
                    placeholder="Horário"
                    className="form-control"
                    autoComplete="off"
                    parse={formatString("99:99")}                    
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="alimentosJantar">Alimentos</label>
                  <Field
                    name="alimentosJantar"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="qtdeJantar">Quantidades</label>
                  <Field
                    name="qtdeJantar"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                <label htmlFor="obsJantar">Observações</label>
                  <Field
                    name="obsJantar"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <hr />
            <p className="recordatorioTitle">Ceia</p>         
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="ceia">Horário</label>
                  <Field
                    name="ceia"
                    component="input"
                    type="text"
                    placeholder="Horário"
                    className="form-control"
                    autoComplete="off"
                    parse={formatString("99:99")}                    
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="alimentosCeia">Alimentos</label>
                  <Field
                    name="alimentosCeia"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group">
                  <label htmlFor="qtdeCeia">Quantidades</label>
                  <Field
                    name="qtdeCeia"
                    component="textarea"
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                <label htmlFor="obsCeia">Observações</label>
                  <Field
                    name="obsCeia"
                    component="textarea"
                    className="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <If show={props.anamnese.dataDesativacao == null}>
              <div className="row">
                <div className="offset-md-10 col-md-2 offset-6 col-6">
                  <button
                    type="submit"
                    className="btn btn-block btn-outline-primary btn-sm"
                    title="Salvar"
                    disabled={Object.keys(props.anamnese).length ? false : true}
                  >
                    <i className="fas fa-save"></i> Salvar
                  </button>
                </div>
              </div>
            </If>
          </form>
        )}
      </Form>
    </div>
  );
};

export default RecordatorioForm;
