import React from "react";

export default (props) => (
  <React.Fragment>
    <hr />
    <div className="row">
      <div className="col-12">
        <div className="card card-widget widget-user-2">
          <div className="card-footer p-0">
            <ul className="nav flex-column">
              <li className="nav-item">
                <a className="nav-link">
                  Altura²
                  <span className="float-right badge bg-primary">
                    {props.alturaAoQuadrado || 0}
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  Imc habitual
                  <span className="float-right badge bg-info">{props.imcHabitual || 0}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  Imc Atual
                  <span className="float-right badge bg-success">{props.imcAtual || 0}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  GEB
                  <span className="float-right badge bg-danger">{props.geb || 0}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  NET
                  <span className="float-right badge bg-warning">{props.net || 0}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  Ingestão Hídrica Ideal
                  <span className="float-right badge bg-secondary">{props.ihi || 0}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6">
        <button type="button" className="btn btn-block btn-warning btn-sm" onClick={props.editarCalculos}>
          <i className="fas fa-edit"></i> Editar Calculos Automáticos
        </button>
      </div>
      <hr />
      <div className="col-12 col-xl-6">
        <button
          type="button"
          className="btn btn-block btn-primary btn-sm"
          onClick={() => {props.gerarCalculos();props.setFields()}}
        >
          <i className="fas fa-calculator"></i> Gerar Calculos Automáticos
        </button>
      </div>
    </div>
    <hr />
  </React.Fragment>
);
