import { returnDate } from "../common/functions/returnDate.js";

const INITIAL_STATE = {
  values: [],
  anamneseListagem: [],
  avaliacaoListagem: [],
  anamnese: [],
  recordatorio: [],
  avaliacao: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FORM_FILLED":
      return {
        ...state,
        values: returnDate(action.payload.data, ["nascimento"]),
      };
    case "ANAMNESE_LISTAGEM":
      return {
        ...state,
        anamneseListagem: action.payload.data,
      };
    case "AVALIACAO_LISTAGEM":
      return {
        ...state,
        avaliacaoListagem: action.payload == true ? [] : action.payload.data,
      };
    case "ANAMNESE":
      return {
        ...state,
        anamnese: action.payload == true ? [] : action.payload.data,
      };
    case "RECORDATORIO":
      return {
        ...state,
        recordatorio: action.payload == true ? [] : action.payload.data,
      };
    case "AVALIACAO":
      return {
        ...state,
        avaliacao: action.payload == true ? [] : action.payload.data,
      };
    default:
      return state;
  }
};
