import React, { useState, useEffect } from "react";
import axios from "axios";
import ContentAtivo from "../common/template/contentAtivo";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { config } from "../common/base";

export default (props) => {
  const [data, setData] = useState([]); // CHECA SE OS DADOS FORAM ATUALIZADOS
  const [checked, setChecked] = useState(true); // FILTRO PARA CADASTRADOS ATIVOS / INATIVOS

  const columns=[{name: "Nome", sortable: true, selector: (row)=> row.nome},
  {name: "Email", sortable: true, selector: (row)=> row.email}]; 
  
  const tableData={columns, data};
  useEffect(() => {
    axios
      .get(`${config.BASE_URL}/pacientes?sort=nome&select=nome%20email&ativo=${checked}`)
      .then((resp) => setData(resp.data));
  }, [checked, props.hash]);

  return (
    <React.Fragment>
      <ContentAtivo checked={checked} whenChanged={setChecked} />
      <DataTableExtensions {...tableData} export={false} print={false}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          pagination
          highlightOnHover
          onRowClicked={(e) => props.onEdit(e._id)}
        />
      </DataTableExtensions>
    </React.Fragment>
  );
};
