import axios from "axios";
import { toastr } from "react-redux-toastr";
import toUp from "../common/functions/firstLettertoUp";
import toLower from "../common/functions/toLower";

import { config } from "../common/base";

export function getAnamneseListagem(paciente) {
  const request = axios.get(`${config.BASE_URL}/anamnese/getAnamneseListagem?paciente=${paciente}`);
  return {
    type: "ANAMNESE_LISTAGEM",
    payload: request,
  };
}

export function getAvaliacoesListagem(anamnese, reset = false) {
  const request =
    reset == true
      ? true
      : axios.get(`${config.BASE_URL}/avaliacao?anamnese=${anamnese}`);
  return {
    type: "AVALIACAO_LISTAGEM",
    payload: request,
  };
}

export function getAnamnese(id, reset = false) {
  const request = reset == true ? true : axios.get(`${config.BASE_URL}/anamnese/${id}`);
  return {
    type: "ANAMNESE",
    payload: request,
  };
}

export function getRecordatorio(anamnese, reset = false) {
  const request = reset == true ? true : axios.get(`${config.BASE_URL}/recordatorio?anamnese=${anamnese}`);
  return {
    type: "RECORDATORIO",
    payload: request,
  };
}

export function getAvaliacao(id, reset = false) {
  const request = reset == true ? true : axios.get(`${config.BASE_URL}/avaliacao/${id}`);
  return {
    type: "AVALIACAO",
    payload: request,
  };
}

export function getForm(id) {
  const request = axios.get(`${config.BASE_URL}/pacientes?_id=${id}`);
  return {
    type: "FORM_FILLED",
    payload: request,
  };
}

export function create(values) {
  return submit(values, "post");
}

export function update(values) {
  return submit(values, "put");
}

export function remove(values) {
  return submit(values, "delete");
}

function submit(values, method) {
  let msg = "";
  switch (method) {
    case "post":
      msg = "incluir um novo";
      break;
    case "put":
      msg = "editar um";
      break;
    case "delete":
      msg = "excluir um";
      break;
  }
  return (dispatch) => {
    const id = values._id ? values._id : "";
    if(values.nome !== undefined) values.nome = toUp(values.nome);
    if(values.email !== undefined) values.email = toLower(values.email);
    axios[method](`${config.BASE_URL}/pacientes/${id}`, values)
      .then((resp) => {
        toastr.success("Sucesso", `Você acabou de ${msg} paciente!!!`);
        if (method == "post") dispatch(showUpdate(resp.data._id));
        if (method == "put") dispatch(getForm(resp.data._id));        
      })
      .catch((e) => {
        e.response.data.errors.forEach((error) => toastr.error("Erro", error));
      });
  };
}

export function showUpdate(id) {
  return [
    getForm(id),
    getAnamneseListagem(id),
    initConsultas(),
  ];
}

export function initConsultas(id=false) {
  if(id == false){
    return [
      getAvaliacoesListagem(0, true),
      getAnamnese(0, true),
      getRecordatorio(0, true),
      getAvaliacao(0, true),
    ];  
  }else{
    return [
      getAvaliacoesListagem(id),
      getAnamnese(id),
      getRecordatorio(id),
      getAvaliacao(0, true),
    ];
  }
}
