import getAge from "../common/functions/getAge";

// Altura ao quadrado
// PESO * 2
export const funcAlturaAoQuadrado = (e) => {
  e = e == undefined || null ? "" : e;
  if (e == "") {
    return "0";
  } else {
    return (parseFloat(e.replace(",", ".")) * 2)
      .toFixed(2)
      .toString()
      .replace(".", ",");
  }
};

// IMC
// PESO * 2 DIVIDIDO ALTURA IDEAL
export const funcImc = (peso, altura) => {
  peso = peso == "" ? "0" : peso;
  altura = altura == "0,00" ? "0" : altura;

  if (peso == 0 && altura == 0) {
    return "0";
  } else {
    return (
      parseFloat(peso.replace(",", ".")) / parseFloat(altura.replace(",", "."))
    )
      .toFixed(2)
      .slice(0, -1)
      .toString()
      .replace(".", ",");
  }
};

function monthDiff(start_date, end_date) {
  return (
    (end_date.getFullYear() - start_date.getFullYear()) * 12 +
    (end_date.getMonth() - start_date.getMonth())
  );
}

// GEB
export const funcGeb = (peso, nascimento, sexo) => {
  if (peso == "") {
    return "0";
  } else {
    const dataAtual = new Date().toISOString().slice(0, 10);
    const idade = getAge(nascimento);
    let checkAge = {
      "0-3": false,
      "3-10": false,
      "10-18": false,
      "18-30": false,
      "30-60": false,
    };

    // SE A IDADE FOR UMA DESSAS ABAIXO, CHECA SE A TABELA VAI SE REFERIR DE 0-3 08 3-10 POR EXEMPLO
    // RANGE DE 6 MESES
    // O ANO DA DATA ATUAL FICA IGUALADA A DATA DE ANIVERSARIO PARA QUE DE CERTO A CONTAGEM DE MESES
    if (
      idade == 3 ||
      idade == 10 ||
      idade == 18 ||
      idade == 30 ||
      idade == 60
    ) {
      // const anoNascimentoFormatted =
      //   parseFloat(nascimento.substr(5, 2)) - 1 <
      //   parseFloat(dataAtual.substr(5, 2)) - 1
      //     ? parseFloat(nascimento.substr(0, 4)) + 1
      //     : nascimento.substr(0, 4);

      // const dataFormated = new Date(
      //   nascimento.substr(0, 4),
      //   parseFloat(dataAtual.substr(5, 2)) - 1,
      //   dataAtual.substr(8, 2)
      // );

      // const nascimentoFormated = new Date(
      //   anoNascimentoFormatted,
      //   parseFloat(nascimento.substr(5, 2)) - 1,
      //   nascimento.substr(8, 2)
      // );

      // NUMERO DE DIFERENCA ENTRE O MES ATUAL PARA O MES DE ANIVERSARIO
      // const difference = parseFloat(monthDiff(dataFormated, nascimentoFormated));

      // const d1 =
      //   parseFloat(dataAtual.substr(5, 2)) - parseFloat(nascimento.substr(5, 2));
      // const d2 =
      //   parseFloat(nascimento.substr(5, 2)) - parseFloat(dataAtual.substr(5, 2));

      const diff =
        parseFloat(dataAtual.substr(5, 2)) -
        parseFloat(nascimento.substr(5, 2));
      const difference2 = diff >= 0 ? diff : 13;

      if (idade == 3) {
        if (difference2 < 6) {
          checkAge["0-3"] = true;
        } else {
          checkAge["3-10"] = true;
        }
        // checkAge["0-3"] = difference2 < 6 ? true : false;
        // checkAge["3-10"] = difference >= 6 ? true : false;

        //     || difference2 <= 4) {
        //     checkAge["0-3"] = true;
        //   } else {
        //     checkAge["3-10"] = true;
        //   }
      }

      if (idade == 10) {
        if (difference2 < 6) {
          checkAge["3-10"] = true;
        } else {
          checkAge["10-18"] = true;
        }
      }

      if (idade == 18) {
        if (difference2 < 6) {
          checkAge["10-18"] = true;
        } else {
          checkAge["18-30"] = true;
        }
      }

      if (idade == 30) {
        if (difference2 < 6) {
          checkAge["18-30"] = true;
        } else {
          checkAge["30-60"] = true;
        }
      }
    }

    let geb = "";
    const newPeso = parseFloat(peso.replace(",", "."));
    // MASCULINO
    if (sexo == "masculino") {
      switch (true) {
        // 0-3
        case parseFloat(idade) <= 2:
        case parseFloat(idade) == 3 && checkAge["0-3"] == true:
          geb = 60.9 * newPeso - 54;
          break;
        // 3-10
        case (parseFloat(idade) == 3 || parseFloat(idade) == 10) &&
          checkAge["3-10"] == true:
        case parseFloat(idade) >= 4 && parseFloat(idade) <= 9:
          geb = 22.7 * newPeso + 495;
          break;
        // 10-18
        case (parseFloat(idade) == 10 || parseFloat(idade) == 18) &&
          checkAge["10-18"] == true:
        case parseFloat(idade) >= 11 && parseFloat(idade) <= 17:
          geb = 17.5 * newPeso + 651;
          break;
        // 18-30
        case (parseFloat(idade) == 18 || parseFloat(idade) == 30) &&
          checkAge["18-30"] == true:
        case parseFloat(idade) >= 19 && parseFloat(idade) <= 29:
          geb = 15.3 * newPeso + 679;
          break;
        // 30-60
        case (parseFloat(idade) == 30 || parseFloat(idade) == 60) &&
          checkAge["30-60"] == true:
        case parseFloat(idade) >= 31 && parseFloat(idade) <= 59:
          geb = 11.6 * newPeso + 879;
          break;
        // > 60
        case parseFloat(idade) > 60:
          geb = 13.4 * newPeso + 487;
          break;
      }
    } else {
      // FEMINIMO
      switch (true) {
        // 0-3
        case parseFloat(idade) <= 2:
        case parseFloat(idade) == 3 && checkAge["0-3"] == true:
          geb = 61.0 * newPeso - 54;
          break;
        // 3-10
        case (parseFloat(idade) == 3 || parseFloat(idade) == 10) &&
          checkAge["3-10"] == true:
        case parseFloat(idade) >= 4 && parseFloat(idade) <= 9:
          geb = 22.5 * newPeso + 499;
          break;
        // 10-18
        case (parseFloat(idade) == 10 || parseFloat(idade) == 18) &&
          checkAge["10-18"] == true:
        case parseFloat(idade) >= 11 && parseFloat(idade) <= 17:
          geb = 12.2 * newPeso + 746;
          break;
        // 18-30
        case (parseFloat(idade) == 18 || parseFloat(idade) == 30) &&
          checkAge["18-30"] == true:
        case parseFloat(idade) >= 19 && parseFloat(idade) <= 29:
          geb = 14.7 * newPeso + 496;
          break;
        // 30-60
        case (parseFloat(idade) == 30 || parseFloat(idade) == 60) &&
          checkAge["30-60"] == true:
        case parseFloat(idade) >= 31 && parseFloat(idade) <= 59:
          geb = 8.7 * newPeso + 829;
          break;
        // > 60
        case parseFloat(idade) > 60:
          geb = 10.5 * newPeso + 596;
          break;
      }
    }

    const formatter = new Intl.NumberFormat("de-DE");

    return formatter.format(geb).toString();
  }
};

// NET
export const funcNet = (geb, nascimento, sexo, atividade) => {
  geb = geb == undefined || null ? "" : geb;
  if (geb == "" || atividade == "") {
    return "0";
  } else {
    let net = "";
    geb = parseFloat(geb.replace(".", "").replace(",", "."));
    const idade = getAge(nascimento);
    if (idade <= 65) {
      if (sexo == "masculino") {
        switch (atividade) {
          case "leve":
            net = geb * 1.55;
            break;
          case "moderada":
            net = geb * 1.8;
            break;
          case "intensa":
            net = geb * 2.0;
            break;
        }
      } else {
        switch (atividade) {
          case "leve":
            net = geb * 1.55;
            break;
          case "moderada":
            net = geb * 1.65;
            break;
          case "intensa":
            net = geb * 1.8;
            break;
        }
      }
    } else {
      if (sexo == "masculino") {
        switch (atividade) {
          case "leve":
            net = geb * 1.4;
            break;
          case "moderada":
            net = geb * 1.6;
            break;
          case "intensa":
            net = geb * 1.9;
            break;
        }
      } else {
        switch (atividade) {
          case "leve":
            net = geb * 1.4;
            break;
          case "moderada":
            net = geb * 1.6;
            break;
          case "intensa":
            net = geb * 1.8;
            break;
        }
      }
    }
    const formatter = new Intl.NumberFormat("de-DE");

    return formatter.format(net.toFixed(1)).toString();
  }
};

//INGESTAO HIDRICA IDEAL
export const funcIhi = (peso, nascimento) => {
  if (peso == "") {
    return "0";
  } else {
    const newPeso = parseFloat(peso.replace(",", "."));
    const newPeso2 = parseFloat(peso);
    const idade = getAge(nascimento);
    let ihi = "";
    if (idade >= 16) {
      switch (true) {
        case idade > 75:
          ihi = newPeso * 25;
          break;
        case idade >= 55 && idade <= 75:
          ihi = newPeso * 30;
          break;
        case idade >= 16 && idade < 55:
          ihi = newPeso * 40;
          break;
      }
    } else {
      switch (true) {
        case newPeso2 > 20:
          ihi = newPeso * 25;
          break;
        case newPeso2 >= 11 && newPeso2 <= 20:
          ihi = newPeso * 50;
          break;
        case newPeso2 <= 10:
          ihi = newPeso * 100;
          break;
      }
    }

    const formatter = new Intl.NumberFormat("de-DE");

    return formatter.format(ihi).toString();
  }
};
