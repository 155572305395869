import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import formatString from "format-string-by-pattern";
import If from "../common/operator/if";
import { returnDateDiaMesAno } from "../common/functions/returnDate";
import leftZero from "../common/functions/leadZero";
import getAge from "../common/functions/getAge";

import {
  funcAlturaAoQuadrado,
  funcImc,
  funcGeb,
  funcNet,
  funcIhi,
} from "./formulas";
import CalculosAutomaticos from "./calculosAutomaticos";

const AvaliacaoForm = (props) => {
  const [itensAvaliacao, setItensAvaliacao] = useState([]);
  const [formData, setFormData] = useState(props.avaliacao);

  // SET PARA MASCARAS DOS CAMPOS ABAIXOS
  const [pesoHabitual, setPesoHabitual] = useState("9,99");
  const [pesoIdeal, setPesoIdeal] = useState("9,99");
  const [pesoAtual, setPesoAtual] = useState("9,99");
  const [circAbdominal, setCircAbdominal] = useState("9,99");
  const [circCintura, setCircCintura] = useState("9,99");
  const [circQuadril, setCircQuadril] = useState("9,99");
  const [circBraco, setCircBraco] = useState("9,99");
  const [circCoxa, setCircCoxa] = useState("9,99");
  const [circPanturrilha, setCircPanturrilha] = useState("9,99");
  const [circMama, setCircMama] = useState("9,99");
  const [circPeitoralAberto, setCircPeitoralAberto] = useState("9,99");
  const [circPeitoralFechado, setCircPeitoralFechado] = useState("9,99");
  const [circOmbro, setCircOmbro] = useState("9,99");
  const [gorduraCorporal, setGorduraCorporal] = useState("9,99");
  const [gorduraVisceral, setGorduraVisceral] = useState("9,99");
  const [massaMagra, setMassaMagra] = useState("9,99");

  // SET PARA OS VALORES NOS CAMPOS ABAIXO
  const [valAltura, setValAltura] = useState("");
  const [valPesoHabitual, setValPesoHabitual] = useState("");
  const [valPesoAtual, setValPesoAtual] = useState("");
  const [valPesoIdeal, setValPesoIdeal] = useState("");
  const [valTipoAtividade, setTipoAtividade] = useState("");

  // SET PARA Os VALORES QUE SERAO ENVIADOS AO "GERAR CALCULOS AUTOMATICOS"
  const [showValAlturaAoQuadrado, setShowValAlturaAoQuadrado] = useState("");
  const [showValImcHabitual, setShowValImcHabitual] = useState("");
  const [showValImcAtual, setShowValImcAtual] = useState("");
  const [showValGeb, setShowValGeb] = useState("");
  const [showValNet, setShowValNet] = useState("");
  const [showValIhi, setShowValIhi] = useState("");

  const [showGebNet, setShowGebNet] = useState(false);

  // FUNC PARA INICIAR UMA NOVA CONSULTA
  const novaConsulta = () => {
    const qtdeAvaliacoes = Object.keys(props.avaliacaoListagem).length
      ? Object.keys(props.avaliacaoListagem).length
      : 0;
    setFormData({
      anamnese: props.anamnese._id,
      avaliacao: qtdeAvaliacoes + 1,
    });

    setValAltura("");
    setValPesoHabitual("");
    setValPesoAtual("");
    setValPesoIdeal("");
    setTipoAtividade("");

    setShowValAlturaAoQuadrado("");
    setShowValImcHabitual("");
    setShowValImcAtual("");
    setShowValGeb("");
    setShowValIhi("");

    setShowGebNet(false);
  };

  // LISTAGEM DE AVALIACOES
  const funcItensAvaliacao = props.avaliacaoListagem.map(
    (itensAvaliacaoNumero) => {
      return (
        <li
          key={itensAvaliacaoNumero._id}
          className="list-group-item text-right"
        >
          <a
            id={itensAvaliacaoNumero._id}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.changeAvaliacao(itensAvaliacaoNumero._id);
            }}
          >
            Consulta:{" "}
            <strong>{leftZero(itensAvaliacaoNumero.avaliacao, 2)}</strong> -
            Data:{" "}
            <strong>
              {returnDateDiaMesAno(itensAvaliacaoNumero.createdAt)}
            </strong>
          </a>
        </li>
      );
    }
  );

  // FUNC PARA GERACAO DE CALCULOS AUTOMATICOS
  const gerarCalculos = () => {
    setShowValAlturaAoQuadrado(funcAlturaAoQuadrado(valAltura));
    setShowValImcHabitual(
      funcImc(valPesoHabitual, funcAlturaAoQuadrado(valAltura))
    );
    setShowValImcAtual(funcImc(valPesoAtual, funcAlturaAoQuadrado(valAltura)));
    setShowValGeb(
      funcGeb(
        valPesoIdeal,
        props.dadosPessoais.nascimento,
        props.dadosPessoais.sexo
      )
    );
    setShowValIhi(funcIhi(valPesoIdeal, props.dadosPessoais.nascimento));

    const idade = getAge(props.dadosPessoais.nascimento);
    if (idade == 3 || idade == 10 || idade == 18 || idade == 30 || idade == 60)
      toastr.warning(
        "Atenção:",
        `Paciente possui ${idade} anos! Fique atento no calculo do GEB e consequentemente do NET.`
      );
  };

  // JOGA OS VALORES NOS CAMPOS AO GERAR OS CALCULOS AUTOMATICOS
  const setFuncFields = ([], state, { changeValue }) => {
    const fields = [
      "alturaQ",
      "imcHabitual",
      "imcAtual",
      "geb",
      "net",
      "ingestaoHidricaIdeal",
    ];
    const values = [
      showValAlturaAoQuadrado,
      showValImcHabitual,
      showValImcAtual,
      showValGeb,
      showValNet,
      showValIhi,
    ];
    fields.map((e, index) => {
      changeValue(state, fields[index], () => values[index]);
    });
  };

  useEffect(() => {
    setFormData(props.avaliacao);
    setItensAvaliacao(funcItensAvaliacao);

    setValAltura(props.avaliacao.altura);
    setValPesoHabitual(props.avaliacao.pesoHabitual);
    setValPesoAtual(props.avaliacao.pesoAtual);
    setValPesoIdeal(props.avaliacao.pesoIdeal);
    setTipoAtividade(
      props.avaliacao.atividade == 1
        ? props.anamnese.tipoAtividade
        : props.avaliacao.tipoAtividade
    );

    setShowValAlturaAoQuadrado(props.avaliacao.alturaQ);
    setShowValImcHabitual(props.avaliacao.imcHabitual);
    setShowValImcAtual(props.avaliacao.imcAtual);
    setShowValGeb(props.avaliacao.geb);
    setShowValNet(props.avaliacao.net);
    setShowValIhi(props.avaliacao.ingestaoHidricaIdeal);

    setShowGebNet(false);
  }, [props.avaliacao, props.avaliacaoListagem]);

  useEffect(() => {
    //setShowValNet depende do GEB, entao só inicia quando o GEB eh calculado
    if (showValGeb == "") {
      setShowValNet("");
    } else {
      const tipoAtividade =
        formData["avaliacao"] > 1
          ? valTipoAtividade
          : props.anamnese.tipoAtividade;

      setShowValNet(
        funcNet(
          showValGeb,
          props.dadosPessoais.nascimento,
          props.dadosPessoais.sexo,
          tipoAtividade
        )
      );
    }
  }, [showValGeb]);

  return (
    <div className="post">
      <If show={Object.keys(props.avaliacaoListagem).length}>
        <div className="card card-light collapsed-card">
          <div className="card-header">
            <h3 className="card-title">Histórico</h3>

            <div className="card-tools">
              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="collapse"
              >
                <i className="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div className="card-body">
            <ul className="list-group">{itensAvaliacao}</ul>
          </div>
        </div>
      </If>
      <If show={props.anamnese.dataDesativacao == null}>
        <div className="row">
          <div className="offset-md-9 col-md-3 col-12">
            <button
              type="button"
              className="btn btn-block btn-outline-primary btn-sm"
              onClick={novaConsulta}
              disabled={Object.keys(props.anamnese).length ? false : true}
            >
              <i className="fas fa-plus"></i> Adicionar
            </button>
          </div>
        </div>
      </If>
      <Form
        onSubmit={props.onSubmit}
        initialValues={{ ...formData }}
        mutators={{ setFuncFields }}
      >
        {({ handleSubmit, form, dirtyFields }) => (
          <form
            role="form"
            onSubmit={(event) => {
              handleSubmit(event);
              // if (dirtyFields["_state"] && props.button == "Incluir")
              //   form.reset();
            }}
          >
            <Field name="_id" component="input" type="hidden" />
            <Field name="anamnese" component="input" type="hidden" />
            <Field name="avaliacao" component="input" type="hidden" />
            <Field name="alturaQ" component="input" type="hidden" />
            <Field name="imcHabitual" component="input" type="hidden" />
            <If show={formData["avaliacao"] > 1}>
              <div className="user-block">
                <span>Objetivo Nutricional</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="objetivo"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Acompanhamento Médico?</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="acompanhamento"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Problema de Saúde?</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="saude"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Medicamentos Utilizados</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="medicamentos"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Exames Laboratoriais</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="exames"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Sintomas Físicos?</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="sintomas"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Bebida Alcoolica?</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="bebida"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Funcionamento Intestinal</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="intestinal"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="user-block">
                <span>Ingestão Hídrica</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="hidrica"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <label htmlFor="Tipo de Atividade">Tipo de Atividade</label>
                  <Field
                    name="tipoAtividade"
                    component="select"
                    className="form-control"
                  >
                    <option value="">Selecione</option>
                    <option value="leve">Leve</option>
                    <option value="moderada">Moderada</option>
                    <option value="intensa">Intensa</option>
                  </Field>
                  <OnChange name="tipoAtividade">
                    {(value, previous) => {
                      setTipoAtividade(value);
                    }}
                  </OnChange>
                </div>
                <div className="col-12 col-md-9">
                  <label htmlFor="atividade">Atividade</label>
                  <div className="form-group">
                    <Field
                      name="atividade"
                      component="textarea"
                      className="form-control"
                      rows="1"
                    />
                  </div>
                </div>
              </div>
            </If>
            <If show={formData["avaliacao"] >= 1}>
              <CalculosAutomaticos
                alturaAoQuadrado={showValAlturaAoQuadrado}
                imcHabitual={showValImcHabitual}
                imcAtual={showValImcAtual}
                geb={showValGeb}
                net={showValNet}
                ihi={showValIhi}
                gerarCalculos={gerarCalculos}
                editarCalculos={() => setShowGebNet(true)}
                setFields={() =>
                  setTimeout(() => {
                    form.mutators.setFuncFields();
                  }, 1000)
                }
              />
              <div
                className={`row gebNet ${
                  showGebNet == true ? "active" : "inactive"
                }`}
              >
                <div className="col-12 col-xl-4">
                  <div className="form-group">
                    <label htmlFor="geb">GEB</label>
                    <Field
                      name="geb"
                      component="input"
                      type="text"
                      placeholder="GEB"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-12 col-xl-4">
                  <div className="form-group">
                    <label htmlFor="net">NET</label>
                    <Field
                      name="net"
                      component="input"
                      type="text"
                      placeholder="NET"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-12 col-xl-4">
                  <div className="form-group">
                    <label htmlFor="net">Ingestão Hídrica Ideal</label>
                    <Field
                      name="ingestaoHidricaIdeal"
                      component="input"
                      type="text"
                      placeholder="Ingestão Hídrica Ideal"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="altura">Altura</label>
                    <Field
                      name="altura"
                      component="input"
                      type="text"
                      placeholder="Altura"
                      className="form-control"
                      parse={formatString("9,99")}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="altura">
                      {(value, previous) => {
                        setValAltura(value);
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="pesoHabitual">Peso habitual</label>
                    <Field
                      name="pesoHabitual"
                      component="input"
                      type="text"
                      placeholder="Peso habitual"
                      className="form-control"
                      parse={formatString(pesoHabitual)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="pesoHabitual">
                      {(value, previous) => {
                        setValPesoHabitual(value);
                        value.length == 4
                          ? setPesoHabitual("999,9")
                          : setPesoHabitual("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="pesoAtual">Peso atual</label>
                    <Field
                      name="pesoAtual"
                      component="input"
                      type="text"
                      placeholder="Peso atual"
                      className="form-control"
                      parse={formatString(pesoAtual)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="pesoAtual">
                      {(value, previous) => {
                        setValPesoAtual(value);
                        value.length == 4
                          ? setPesoAtual("999,9")
                          : setPesoAtual("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="pesoIdeal">Peso Ideal</label>
                    <Field
                      name="pesoIdeal"
                      component="input"
                      type="text"
                      placeholder="Peso Ideal"
                      className="form-control"
                      parse={formatString(pesoIdeal)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="pesoIdeal">
                      {(value, previous) => {
                        setValPesoIdeal(value);
                        value.length == 4
                          ? setPesoIdeal("999,9")
                          : setPesoIdeal("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="circAbdominal">Circ. Abdominal</label>
                    <Field
                      name="circAbdominal"
                      component="input"
                      type="text"
                      placeholder="Circ. Abdominal"
                      className="form-control"
                      parse={formatString(circAbdominal)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circAbdominal">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircAbdominal("999,9")
                          : setCircAbdominal("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="circCintura">Circ. Cintura</label>
                    <Field
                      name="circCintura"
                      component="input"
                      type="text"
                      placeholder="Circ. Cintura"
                      className="form-control"
                      parse={formatString(circCintura)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circCintura">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircCintura("999,9")
                          : setCircCintura("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-2">
                  <div className="form-group">
                    <label htmlFor="circQuadril">Circ. Quadril</label>
                    <Field
                      name="circQuadril"
                      component="input"
                      type="text"
                      placeholder="Circ. Quadril"
                      className="form-control"
                      parse={formatString(circQuadril)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circQuadril">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircQuadril("999,9")
                          : setCircQuadril("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-2">
                  <div className="form-group">
                    <label htmlFor="circBraco">Circ. Braço</label>
                    <Field
                      name="circBraco"
                      component="input"
                      type="text"
                      placeholder="Circ. Braço"
                      className="form-control"
                      parse={formatString(circBraco)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circBraco">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircBraco("999,9")
                          : setCircBraco("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-2">
                  <div className="form-group">
                    <label htmlFor="circCoxa">Circ. Coxa</label>
                    <Field
                      name="circCoxa"
                      component="input"
                      type="text"
                      placeholder="Circ. Coxa"
                      className="form-control"
                      parse={formatString(circCoxa)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circCoxa">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircCoxa("999,9")
                          : setCircCoxa("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="circPanturrilha">Circ. Panturrilha</label>
                    <Field
                      name="circPanturrilha"
                      component="input"
                      type="text"
                      placeholder="Circ. Panturrilha"
                      className="form-control"
                      parse={formatString(circPanturrilha)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circPanturrilha">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircPanturrilha("999,9")
                          : setCircPanturrilha("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="circMama">Circ. Mama</label>
                    <Field
                      name="circMama"
                      component="input"
                      type="text"
                      placeholder="Circ. Mama"
                      className="form-control"
                      parse={formatString(circMama)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circMama">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircMama("999,9")
                          : setCircMama("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-2">
                  <div className="form-group">
                    <label htmlFor="circPeitoralAberto">Circ. Peito A.</label>
                    <Field
                      name="circPeitoralAberto"
                      component="input"
                      type="text"
                      placeholder="Circ Peitoral Aberto"
                      className="form-control"
                      parse={formatString(circPeitoralAberto)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circPeitoralAberto">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircPeitoralAberto("999,9")
                          : setCircPeitoralAberto("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-2">
                  <div className="form-group">
                    <label htmlFor="circPeitoralFechado">Circ. Peito F.</label>
                    <Field
                      name="circPeitoralFechado"
                      component="input"
                      type="text"
                      placeholder="Circ. Peitoral Fechado"
                      className="form-control"
                      parse={formatString(circPeitoralFechado)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circPeitoralFechado">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircPeitoralFechado("999,9")
                          : setCircPeitoralFechado("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-2">
                  <div className="form-group">
                    <label htmlFor="circOmbro">Circ. Ombro</label>
                    <Field
                      name="circOmbro"
                      component="input"
                      type="text"
                      placeholder="Circ. Ombro"
                      className="form-control"
                      parse={formatString(circOmbro)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="circOmbro">
                      {(value, previous) => {
                        value.length == 4
                          ? setCircOmbro("999,9")
                          : setCircOmbro("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="gorduraCorporal">Gordura Corporal</label>
                    <Field
                      name="gorduraCorporal"
                      component="input"
                      type="text"
                      placeholder="Gordura Corporal"
                      className="form-control"
                      parse={formatString(gorduraCorporal)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="gorduraCorporal">
                      {(value, previous) => {
                        value.length == 4
                          ? setGorduraCorporal("999,9")
                          : setGorduraCorporal("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="gorduraVisceral">Gordura Visceral</label>
                    <Field
                      name="gorduraVisceral"
                      component="input"
                      type="text"
                      placeholder="Gordura Visceral"
                      className="form-control"
                      parse={formatString(gorduraVisceral)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="gorduraVisceral">
                      {(value, previous) => {
                        value.length == 4
                          ? setGorduraVisceral("999,9")
                          : setGorduraVisceral("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="massaMagra">Massa magra</label>
                    <Field
                      name="massaMagra"
                      component="input"
                      type="text"
                      placeholder="Massa magra"
                      className="form-control"
                      parse={formatString(massaMagra)}
                      autoComplete="off"
                      required
                    />
                    <OnChange name="massaMagra">
                      {(value, previous) => {
                        value.length == 4
                          ? setMassaMagra("999,9")
                          : setMassaMagra("99,9");
                      }}
                    </OnChange>
                  </div>
                </div>
                <div className="col-12 col-xl-3">
                  <div className="form-group">
                    <label htmlFor="dietaOrientada">Dieta Orientada</label>
                    <Field
                      name="dietaOrientada"
                      component="input"
                      type="text"
                      placeholder="Dieta Orientada"
                      className="form-control"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="user-block">
                <span>Observação</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Field
                      name="observacao"
                      component="textarea"
                      className="form-control"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="offset-md-10 col-xl-2 offset-6 col-6">
                  <button
                    type="submit"
                    className="btn btn-block btn-outline-primary btn-sm"
                    title="Salvar"
                  >
                    <i className="fas fa-save"></i> Salvar
                  </button>
                </div>
              </div>
            </If>
          </form>
        )}
      </Form>
    </div>
  );
};

export default AvaliacaoForm;
